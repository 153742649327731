<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="page-header">
        <h4 class="page-title" v-if="$v.typeform.$model.city_name == null || $v.typeform.$model.city_name == ''">Add New City</h4>
        <h4 class="page-title" v-if="$v.typeform.$model.city_name">Edit City</h4>
        <!-- <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Tables</a></li>
            <li class="breadcrumb-item active" aria-current="page">Basic tables</li>
          </ol>
        </nav> -->
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">City Details</h4>
              <form class="form-sample">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">                      
                      <div class="col-sm-12">
                        <label class="col-form-label">City Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.city_name.$model" :class="{ 'is-invalid':  $v.typeform.city_name.$error  }"  placeholder="City Name"/>
                        <div v-if="$v.typeform.city_name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.city_name.required">Please enter city name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Status</label>
                        <multiselect v-model="$v.typeform.status.$model" :options="status"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Status"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.status.required">Please select status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                    <button v-if="!is_btn_spinner && this.$route.name == 'addcity'" type="button" @click="submitForm()" class="btn btn-gradient-primary me-2">Submit</button>
                    <button v-if="!is_btn_spinner && this.$route.name == 'editcity'" type="button" @click="editSubmitForm($route.params.id)" class="btn btn-gradient-primary me-2">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>

</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../constant/strings.js"

export default {
    name:'AddCITY',
    title: string.PAGE_TITLE_CITY_DETAILS,
    components: {
      NavBar,
Footer,
      SideBar,
      Multiselect,
    },
    data() {
    return {
      status :['Active','Inactive'],
      typeform:{
        city_name:'',
        status:''
      },
      is_btn_spinner:false
    }
    },
    validations: {
    typeform: {
      city_name:{required},
      status:{required},      
    },    
  },
  mounted(){    
    if(this.$route.params.id){
      this.getCity(this.$route.params.id)
    }
  },
  methods:{    
    ...mapActions("hospital",["addCityData","getCityDetailsData","editCityDetailsData"]),

    submitForm(){
      this.is_btn_spinner = true;
      this.$v.$touch();      
      if (!this.$v.typeform.$invalid) {      
      var bodyFormData = new FormData();      
      bodyFormData.append('city_name', this.typeform.city_name);
      bodyFormData.append('city_status', this.typeform.status);
      this.addCityData(bodyFormData).then((response) => {        
        this.is_btn_spinner = false;
        if (response.response_code == 200) {
          this.$toasted.success("City added successfully", {duration: 2000,});
          this.$router.push({ name: 'cities' });
        }else{
          this.$toasted.error("Something Went wrong", {duration: 2000,});
        }
      });
      }
    },
    editSubmitForm(id){
      this.is_btn_spinner = true;
      this.$v.$touch();
      if (!this.$v.typeform.$invalid) {    
        var bodyFormData = new FormData();      
        bodyFormData.append('city_id', id);
        bodyFormData.append('city_name', this.typeform.city_name);
        bodyFormData.append('city_status', this.typeform.status);
        this.editCityDetailsData(bodyFormData).then((response) => {        
          this.is_btn_spinner = false;
          if (response.response_code == 200) {
            this.$toasted.success("City update successfully", {duration: 2000,});
            this.$router.push({ name: 'cities' });
          }else{
            this.$toasted.error("Something Went wrong", {duration: 2000,});
          }
        });
      }
    },
    getCity(id){
      var bodyFormData = new FormData();      
      bodyFormData.append('city_id', id);
      this.getCityDetailsData(bodyFormData).then((response) => {        
        if (response.response_code == 200) {          
        this.typeform = {
          city_name:response.data.city_name,
          status:response.data.city_status,          
        }
        }else{
          this.$router.push({ name: 'cities' });
        }
      });
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
